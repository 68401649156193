import { RedirectGuard } from './providers/redirect.guard';
import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from './modules/@core/services/auth-guard.service';
import { AuthGuard } from './providers/auth.guard';

export const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  {
    path: "pages",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "auth",
    canActivate: [RedirectGuard],
    loadChildren: () =>
      import("./modules/@core/auth/auth.module").then((m) => m.NgxAuthModule),
  },
  { 
    path: 'settings',
    loadChildren: () => 
      import('./modules/pages/settings/settings.module').then(m => m.SettingsModule)
  },
  { path: 'dashboard', loadChildren: () => import('./modules/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },

  // { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
  // paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  // scrollOffset: [0, 64],
  onSameUrlNavigation: 'ignore',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule { }
